import './replaceCardInfoShow.css'
import React, { Component } from 'react'
import { NavBar,Icon,List,InputItem,ImagePicker,Toast,Modal,Button} from 'antd-mobile';
import { NEW_YGK_URL, Axios } from '../../../utils/url';
const Item = List.Item;
const Brief = Item.Brief;
export default class ReplaceCardInfoShow extends Component {
    constructor(props) {
        super(props);
        console.log(this.props.location.query);
        const replaceId = this.props.location.query.replaceId;
        const replaceStatus = this.props.location.query.replaceStatus;
        
        this.state = {
            replaceId:replaceId,//补卡id
            replaceStatus:replaceStatus,//补卡状态
            hrNo:'',//人员编号
            personName:'',//人员姓名
            gender:'',//性别
            personType:'',//人员类型
            nation:'',//名族
            idCardNo:'',//身份证号码
            phone:'',//联系方式
            list:{},//补卡详单
            packageList:[],//钱包列表
            isLoaded:false,
            files:[],
            imglist:[],//附件列表

            visible:false,
            previewImage:'',
            current:90,
            transStyle:''

        }
    }
    componentDidMount = () => {
        //加载补卡详情单据
        this.getReplaceCardInfoByReplaceId();
        this.getimgList();
    }
    //取消补卡申请
    handleSubmit = e => {
      console.log("取消按钮单击",this.state);
        e.preventDefault()
        console.log('表单提交')
        const url = NEW_YGK_URL+'replacecard/cancelReplaceCardBills';
        let params = new FormData();
        params.append("replaceId", this.state.replaceId);
        params.append("replaceStatus", this.state.replaceStatus);
        console.log(this.state.replaceId);
        Axios({
          method: 'post',
          url: url,
          data: params
        }).then((res) => {
            console.log(res);
            if (res.status == 200) {
              if(res.data.flag == false ){
                Toast.fail(res.data.msg, 1);
              }else{
                Toast.success('取消成功', 1);
                let _that = this;
                setTimeout(function () {
                  _that.props.history.go(-1)
                }, 1000);
              }
            } else {
              Toast.fail('取消失败', 1);
            }
        });
    }

    async getimgList() {
        const url = NEW_YGK_URL+'replacecard/getAffixList';
        var params = new URLSearchParams();
        params.append("replaceId", this.state.replaceId);
        await Axios({
            method: 'post',
            url: url,
            data: params
        }).then((res) => {
            console.log(res);
            if(res.data.flag){
              this.setState({
                  files:res.data.data
                });
            }
            // if (res.status === 200) {
            //     this.setState({
            //       files:res.data
            //     });
            // }
        });
    }



    async getReplaceCardInfoByReplaceId() {
      console.log("获取补卡详情");

        const url = NEW_YGK_URL+'replacecard/getReplaceCardInfoByReplaceId';
        var params = new URLSearchParams();
        params.append("replaceId", this.state.replaceId);
        await Axios({
            method: 'post',
            url: url,
            data: params
        }).then((res) => {
            console.log(res);
            this.setState({
              list: res.data.data[0],
            })
        });
    }

    async getxqList() {
          const url = NEW_YGK_URL+'bkjlzh/page';
          var params = new URLSearchParams();
          params.append("bkid", this.state.bkid);
          await Axios({
              method: 'post',
              url: url,
              data: params
          }).then((res) => {
              console.log(res.data);
              if (res.status === 200) {
                  this.setState({
                    qblist:res.data.records,
                    isLoaded:true
                  });
              }
          });
      }

  imgonclick=(index,files) =>{
    this.setState({
      previewImage: files[index].url,
      visible: true
  });
  }
  onClose=() =>{
    this.setState({
      visible: false
  });
  }

    render() {
        const {files} = this.state

        return (
            <div style={{ marginTop: ".89rem" }}>
            {/* 导航栏#0F84D6 */}
            <NavBar
              mode="dark"
              icon={<Icon type="left" />}
             onLeftClick={() => this.props.history.goBack()}
            >一卡通补卡申请详情</NavBar>
             <form onSubmit={this.handleSubmit}>
             <List renderHeader={() => '申请人基本信息'} >
             <div className="scfj">申请人基本信息</div>
             <InputItem
              value={this.state.list.PERSON_NAME}
              ref={el => this.labelFocusInst = el}
              editable
            ><div >申请人姓名</div></InputItem>

            <InputItem
              value={this.state.list.HR_NO}
              ref={el => this.labelFocusInst = el}
              editable
            ><div >人员编号</div></InputItem>

            <InputItem
              value={this.state.list.GENDER}
              ref={el => this.labelFocusInst = el}
              editable
            ><div >性别</div></InputItem>

            <InputItem
              value={this.state.list.PERSON_TYPE}
              ref={el => this.labelFocusInst = el}
              editable
            ><div >类型名称</div></InputItem>

            <InputItem
              value={this.state.list.NATION}
              ref={el => this.labelFocusInst = el}
              editable
            ><div >民族</div></InputItem>

            <InputItem
              value={this.state.list.ID_CARD_NO}
              ref={el => this.labelFocusInst = el}
              editable
            ><div >证件编号</div></InputItem>

            <InputItem
              value={this.state.list.PHONE}
              ref={el => this.labelFocusInst = el}
              editable
            ><div >联系手机号</div></InputItem>
            <InputItem
              value={this.state.list.AUDIT_OPINION}
              editable
            ><div >意见</div></InputItem>
            {/* <div className="scfj">余额(元)</div>
            {this.setMapList()} */}
            <div>已上传附件</div>
            <ImagePicker
              files={files}
              onChange={this.onChange}
              onImageClick={this.imgonclick}
              selectable={files.length < 4}
              multiple = {this.state.multiple}
              //selectable = {false}
            />
            <Modal
                    visible={ this.state.visible }
                    transparent
                    footer={[{ text: '关闭', onPress: () => { console.log('ok'); this.onClose(); } }]}
                >
                    <div style={{ marginTop:50, transform:this.state.transStyle, display:'flex', alignItems:'center'}}>
                        <img
                            alt="example"
                            style={{width: '100%',height:'100%' }}
                            src={this.state.previewImage}
                        />
                    </div>
                </Modal>
            </List>

            <div className="formSubmit">
              <button className="submit" type="submit">
                取 消 申 请
              </button>
            </div>

             </form>

            </div>
        )
    }
}
